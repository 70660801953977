import Home from "./pages/Home";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/main.css';
import Background_WEBGL from "./components/Background_WEBGL";
import {useEffect} from "react";
function App() {

  useEffect(() => {
      window.gtag('set', 'page_path', '/');
      window.gtag('event', 'page_view', {page_title: "Home"});
  }, [])

  return (
   <>
       <Background_WEBGL />
       <Home />
   </>
  );
}

export default App;
