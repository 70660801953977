import {Badge, Button, Card, Col, Container, Form, Nav, Navbar, Offcanvas, Row} from "react-bootstrap";
import logo from "../assets/img/Logo_Datasourcery.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useEffect, useState} from "react";
import {ActiveCampaignInputs, handleActiveCampaignSubmit} from 'active-campaign-react'
import {useForm} from 'react-hook-form';

const NavMenu = () => {
	return (
		<Navbar collapseOnSelect expand="lg" sticky="top">
			<Container>
				<Navbar.Brand href="#home">
					<img
						alt=""
						src={logo}
						height="50"
						className="d-inline-block align-top"
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav"/>
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="me-auto">
						<Nav.Link href="#home"
						          variant={"active"}
						          onClick={() => {
							          window.gtag('set', 'page_path', '/');
							          window.gtag('event', 'page_view', {page_title: "Home"});
						          }
						          }
						>Home</Nav.Link>
						<Nav.Link href="#features"
						          onClick={() => {
							          window.gtag('set', 'page_path', '/expertise');
							          window.gtag('event', 'page_view', {page_title: "Expertise"});
						          }
						          }>Expertise</Nav.Link>
						<Nav.Link href="#clients"
						          onClick={() => {
							          window.gtag('set', 'page_path', '/clients');
							          window.gtag('event', 'page_view', {page_title: "Clients"});
						          }
						          }>Clients</Nav.Link>
						<Nav.Link href="#projects"
						          onClick={() => {
							          window.gtag('set', 'page_path', '/projects');
							          window.gtag('event', 'page_view', {page_title: "Projects"});
						          }
						          }>Projects</Nav.Link>
						<Nav.Link href="#engagement"
						          onClick={() => {
							          window.gtag('set', 'page_path', '/engagement');
							          window.gtag('event', 'page_view', {page_title: "Engagement"});
						          }
						          }>Engagement</Nav.Link>
						<Nav.Link href="#contact"
						          onClick={() => {
							          window.gtag('set', 'page_path', '/contact');
							          window.gtag('event', 'page_view', {page_title: "Contact"});
						          }
						          }>Contact</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}

const Hero = () => {
	
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	};
	
	return (
		<Container className={"hero"} id={"home"} style={{height: '100%', padding: '100px 40px 40px 40px'}}>
			<Slider {...settings} style={{marginBottom: 40, height: '100%'}}>
				
				<div style={{height: '100%'}}>
					<Row style={{minHeight: '350px'}}>
						<Col>
							<div>
								<h1 style={{fontSize: '3em'}}>Donor & Fundraising Analytics.</h1>
								<p>
									If you are a charity and know you can make more out of the data you have but you
									don't have the technical resources, we are here to help! And it doesn't really
									matter if you are using Raiser's Edge, Dynamics or other way of managing your
									supporters, events and transactions. We are your partner if you want to build that
									unified supporter view, deeply understand your donor segments or need a more complex
									forecasting model for your regular and cash giving programs.
								</p>
								<Badge style={{marginRight: 5}}>Fundraising analytics</Badge>
								<Badge style={{marginRight: 5}}>Visualisation and reporting</Badge>
								<Badge style={{marginRight: 5}}>Digital transformation</Badge>
								<Badge style={{marginRight: 5}}>Power BI</Badge>
								<p style={{paddingTop: 20}}>
									<a className="btn btn-outline-primary" href={"#contact"}>Get in touch</a>
								</p>
							</div>
						</Col>
						<Col xs lg="4"></Col>
					</Row>
				</div>
				
				<div>
					<Row style={{minHeight: '350px'}}>
						<Col>
							<div>
								<h1 style={{fontSize: '3em'}}>Insight. Advantage.</h1>
								<p>
									We are your strategic partner to help with unleashing the power of your data to
									empower you to make smarter decisions that unlock opportunities for growth.
								</p>
								<Badge style={{marginRight: 5}}>Finance processes</Badge>
								<Badge style={{marginRight: 5}}>Data architecture</Badge>
								<Badge style={{marginRight: 5}}>Visualisation and reporting</Badge>
								<Badge style={{marginRight: 5}}>Process automation</Badge>
								<Badge style={{marginRight: 5}}>App development</Badge>
								<Badge style={{marginRight: 5}}>Management reporting</Badge>
								<Badge style={{marginRight: 5}}>Digital transformation</Badge>
								<p style={{paddingTop: 20}}>
									<a className="btn btn-outline-primary" href={"#contact"}>Explore how we can help</a>
								</p>
							</div>
						</Col>
						<Col xs lg="4"></Col>
					</Row>
				</div>
				
				<div>
					<Row style={{minHeight: '350px'}}>
						<Col>
							<div>
								<h1 style={{fontSize: '3em'}}>Automate your processes.</h1>
								<p>
									Automation and innovative solutions that help you focus on the important tasks. We
									analyse your processes, flows and integrations and help you redesign your operations
									to increase efficiency, output and sustainability.
								</p>
								<Badge style={{marginRight: 5}}>RPA</Badge>
								<Badge style={{marginRight: 5}}>Finance & payroll</Badge>
								<Badge style={{marginRight: 5}}>Backoffice</Badge>
								<Badge style={{marginRight: 5}}>Email & task management</Badge>
								<Badge style={{marginRight: 5}}>ETL</Badge>
								<Badge style={{marginRight: 5}}>Integrations</Badge>
								<p style={{paddingTop: 20}}>
									<a className="btn btn-outline-primary" href={"#contact"}>Arrange a call</a>
								</p>
							</div>
						</Col>
						<Col xs lg="4"></Col>
					</Row>
				</div>
			
			</Slider>
		</Container>
	)
}

const Features = () => {
	return (
		<Container style={{padding: 40}} id={"features"}>
			<h1 className="text-center" style={{marginBottom: 20}}>Core expertise</h1>
			<Row style={{padding: '40px 0'}}>
				<Col>
					<Card style={{height: '100%'}}>
						<Card.Body>
							<Card.Title>Strategic data consulting and data architecture</Card.Title>
							<Card.Text>
								No matter if you want to redesign your services, add personalised functionality based on
								data analytics to your existing services or simply have a new idea you want to explore
								and need some help or sounding board, get in touch to start the conversation.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<Card style={{height: '100%'}}>
						<Card.Body>
							<Card.Title>Actionable insights and reporting frameworks</Card.Title>
							<Card.Text>
								Our approach to segmentation is tailored for non-profit organisations that have direct
								fundraising or recurring F2F and door programs. We can help identify groups within your
								audience, deploy a custom RFM (Recency-Frequency-Monetary) segmentation and surface
								strengths and weaknesses within your overall fundraising program.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<Card style={{height: '100%'}}>
						<Card.Body>
							<Card.Title>Automation of processes, experiences and data flows</Card.Title>
							<Card.Text>
								Automate and personalise your activities, no matter if it is retention, acquisition,
								targeted campaigns or back-office processes.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}

const Clients = () => {
	
	const settings = {
		dots: true,
		infinite: true,
		speed: 100,
		slidesToShow: 3,
		slidesToScroll: 1
	};
	
	return (
		<Container className={"clients"} id={"clients"} style={{padding: '100px 40px'}}>
			<h5 style={{marginBottom: 20}}>Current and previous clients</h5>
			<Row>
				<Col>
					<div>
						<Slider {...settings}>
							<div>SPCA</div>
							<div>Cancer society (Northern, Bay of Plenty, Wellington)</div>
							<div>Unisphere Solutions</div>
							<div>Bottomless Bots / TRAC</div>
							<div>Transformation IQ</div>
							<div>Easy HR</div>
							<div>Greenpeace NZ</div>
							<div>Fred Hollows Foundation</div>
							<div>SpeakData</div>
							<div>Cybertribe</div>
						</Slider>
					</div>
				</Col>
			</Row>
		</Container>
	)
}

const Engagement = () => {
	return (
		<Container id={"engagement"} style={{padding: 40}}>
			<h1 className="text-center" style={{marginBottom: 20}}>Engagement</h1>
			<Row style={{padding: '40px 0'}}>
				<Col>
					<Card style={{height: '100%'}}>
						<Card.Body>
							<Card.Title>Projects</Card.Title>
							<Card.Text>
								<p>Adhoc projects with a fixed scope, for instance:</p>
								<ul>
									<li>Development of Apps & tools</li>
									<li>Investigations and exploration projects</li>
									<li>Data science support</li>
									<li>Deliver Marketing Automation</li>
									<li>Power BI report drafts</li>
									<li>Migration services</li>
								</ul>
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<Card style={{height: '100%'}}>
						<Card.Body>
							<Card.Title>Recurring data services</Card.Title>
							<Card.Text>
								<p>Ongoing working relationship with usually periodic commitment, for instance:</p>
								<ul>
									<li>Usage of our bespoke fundraising data model and corresponding dashboards,
										machine learning processes and other outputs.
									</li>
									<li>Ongoing data maintenance and quality tracking</li>
									<li>Bots that perform automated tasks</li>
									<li>Virtual CDO (Chief Data Officer), fixed monthly commitment</li>
									<li>Analytics support</li>
									<li>Integrations and Marketing Automation maintenance</li>
									<li>Senior Business Analysts resource with ongoing commitment</li>
								</ul>
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}

const Projects = () => {
	return (
		<Container id={"projects"} style={{padding: 40}} className={"projects"}>
			<h1 className="text-center" style={{marginBottom: 20}}>Projects</h1>
			<Row style={{padding: '40px 0'}}>
				<Col>
					<Card style={{height: '100%'}}>
						<Card.Body>
							<Card.Title>Fundraising & donor analytics</Card.Title>
							<Card.Text>
								Optimising your campaigns help you achieve the best ROI for your campaigns and optimise
								your expenditure. Our analytical models that customised to enhance the performance of
								campaigns and protect your fundraising income in a crowded market.
							</Card.Text>
						</Card.Body>
						<Card.Footer>
							<Badge>Power BI</Badge>
							<Badge>R</Badge>
							<Badge>MS Office</Badge>
							<Badge>Raisers Edge</Badge>
							<Badge>Machine learning</Badge>
							<Badge>AI</Badge>
							<Badge>Tableau</Badge>
							<Badge>Marketing automation</Badge>
							<Badge>Propensity scoring</Badge>
							<Badge>Databases</Badge>
						</Card.Footer>
					</Card>
				</Col>
				<Col>
					<Card style={{height: '100%'}}>
						<Card.Body>
							<Card.Title>Management reporting</Card.Title>
							<Card.Text>
								Management reporting systems help in capturing data that is needed by managers to run an
								effective business. Data could range from financial data, employee headcount, client,
								accounts, products, client assets in custody, investment performance, etc. Talk to us
								how we can help create transparency through reporting across your organisation.
							</Card.Text>
						</Card.Body>
						<Card.Footer>
							<Badge>Finance insight</Badge>
							<Badge>Competitive intelligence</Badge>
							<Badge>Workforce</Badge>
						</Card.Footer>
					</Card>
				</Col>
				<Col>
					<Card style={{height: '100%'}}>
						<Card.Body>
							<Card.Title>Process automation</Card.Title>
							<Card.Text>
								Automate and personalise your activities, no matter if it is retention, acquisition,
								targeted campaigns or back-office processes. No matter if you want to redesign your
								services, add personalised functionality based on data analytics to your existing
								services or simply have a new idea you want to explore and need some help or sounding
								board
							</Card.Text>
						</Card.Body>
						<Card.Footer>
							<Badge>RPA</Badge>
							<Badge>Javascript</Badge>
							<Badge>ETL</Badge>
							<Badge>Integration</Badge>
							<Badge>Database design</Badge>
						</Card.Footer>
					</Card>
				</Col>
			</Row>
			<Row style={{padding: '40px 0'}}>
				<Col>
					<Card style={{height: '100%'}}>
						<Card.Body>
							<Card.Title>Visualisation & reporting</Card.Title>
							<Card.Text>
								It is important to track how campaigns are tracking to make sure that investments are
								targeting the best-possible audience and the return on this investment is being
								maximised. We are experts in providing custom dashboard solutions for non-profit
								organisations, and use tools like Tableau, PowerBI or even Excel.
							</Card.Text>
						</Card.Body>
						<Card.Footer>
							<Badge>Power BI</Badge>
							<Badge>Tableau</Badge>
							<Badge>MS Office</Badge>
						</Card.Footer>
					</Card>
				</Col>
				<Col>
					<Card style={{height: '100%'}}>
						<Card.Body>
							<Card.Title>Marketing automation</Card.Title>
							<Card.Text>
								<strong>Personalise your activities, no matter if it is retention, acquisition or
									targeted at increasing giving.</strong> Whether you want to grow or personalise your
								marketing automation (e.g. in Marketo, Mailchimp, Salesforce Marketing Cloud), get
								started with transforming your communication strategy to deliver a unique donor
								experience or apply insights to optimise the online experience across your digital
								channels, success comes with a solid data foundation. We can help you build a coherent
								strategy that focuses on long-term success and income growth.
							</Card.Text>
						</Card.Body>
						<Card.Footer>
							<Badge>Active campaign</Badge>
							<Badge>APIs</Badge>
							<Badge>Marketo</Badge>
							<Badge>Integration</Badge>
						</Card.Footer>
					</Card>
				</Col>
				<Col>
					<Card style={{height: '100%'}}>
						<Card.Body>
							<Card.Title>App development</Card.Title>
							<Card.Text>
								Do you have services that don't talk to each other? You have have a lengthy recurring
								process using excel to manipulate a file only to upload it then to another system you
								use? We can integrate data flows between online services you use on a day-to-day basis
								and make sure you don't miss any insight or wonder what you've could have done if you
								knew your data. Get in touch to start the conversation!
							</Card.Text>
						</Card.Body>
						<Card.Footer>
							<Badge>React</Badge>
							<Badge>Javascript</Badge>
							<Badge>JSON</Badge>
							<Badge>APIs</Badge>
						</Card.Footer>
					</Card>
				</Col>
			</Row>
			<Row style={{padding: '40px 0'}}>
				<Col>
					<Card style={{height: '100%'}}>
						<Card.Body>
							<Card.Title>Actionable insights</Card.Title>
							<Card.Text>
								Become proactive and retain your donors, one contact at a time. Analytical solutions
								that deliver actionable insights tailored for your day-to-day management of recurring
								fundraising (e.g. churn analysis, propensities for increasing current giving or
								reactivation, minimising arrears) and direct activity are crucial for a successful
								fundraising team. With hands-on experience in the sector can use our unique mix of data
								management, data science and analysis to help build your strategies and transform your
								fundraising results.
							</Card.Text>
						</Card.Body>
						<Card.Footer>
							<Badge>Power BI</Badge>
							<Badge>Tableau</Badge>
							<Badge>MS Office</Badge>
						</Card.Footer>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}

const Contact = () => {
	
	const {register, handleSubmit} = useForm();
	const formId = '4';
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [notes, setNotes] = useState('');
	const [valid, setValid] = useState(false);
	const [sent, setSent] = useState(false);
	
	// const onSubmit = async (data) => {
	//     handleActiveCampaignSubmit(data, 'datasourcery', formId)
	// }
	
	function onSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.target);
		
		fetch('https://hook.us1.make.com/5xwh2bj6jojm6mlzf2ka2phwqgunh2sn', {
			method: 'POST',
			body: data,
			mode: 'no-cors',
		})
			.then(response => {
				setSent(true);
				event.target.reset();
			})
			.catch(error => console.log('Request failed', error));
	}
	
	useEffect(() => {
		if (email !== '' && name !== '' && phone !== '' && notes !== '') {
			setValid(true)
		} else {
			setValid(false)
		}
	})
	
	return (
		<Container className={"hero"} id={"contact"} style={{padding: 40}}>
			<h1 className="text-center" style={{marginBottom: 20, fontSize: '2.5em'}}>
				Questions?<br/>
				Find out how we can help you.
			</h1>
			<Row>
				<Col xs={12} md={12} lg={2}></Col>
				<Col xs={12} md={12}  lg={8}>
					<Card>
						<Card.Body>
							{
								(sent) ?
									<h5>Form successful sent.</h5>
									:
									<Form onSubmit={onSubmit}>
										
										<input type="hidden" name="u" value={formId}/>
										<input type="hidden" name="f" value={formId}/>
										<input type="hidden" name="s"/>
										<input type="hidden" name="c" value="0"/>
										<input type="hidden" name="m" value="0"/>
										<input type="hidden" name="act" value="sub"/>
										<input type="hidden" name="v" value="2"/>
										
										<Form.Group className="mb-3" controlId="name">
											<Form.Label>Name</Form.Label>
											<Form.Control value={name}
											              onChange={(e) => {
												              console.log(e);
												              setName(e.target.value)
											              }} name="name"
											/>
											{(name === '') && (
												<p className="text-warning">Please provide an name</p>
											)}
										</Form.Group>
										
										<Form.Group className="mb-3" controlId="email">
											<Form.Label>Email address</Form.Label>
											<Form.Control value={email}
											              error={(email === '')}
											              onChange={(e) => setEmail(e.target.value)}
											              name="email" type="email"
											/>
											{(email === '') && (
												<p className="text-warning">Please provide an email</p>
											)}
										</Form.Group>
										
										<Form.Group className="mb-3" controlId="phone">
											<Form.Label>Phone</Form.Label>
											<Form.Control value={phone}
											              onChange={(e) => setPhone(e.target.value)}
											              name="phone" type="phone"/>
											{(phone === '') && (
												<p className="text-warning">Please provide an contact number</p>
											)}
										</Form.Group>
										
										<Form.Group className="mb-3" controlId="field[13]">
											<Form.Label>Message</Form.Label>
											<Form.Control as="textarea" rows={10} value={notes}
											              onChange={(e) => setNotes(e.target.value)}
											              name="notes"/>
											{(notes === '') && (
												<p className="text-warning">Please provide some context</p>
											)}
										</Form.Group>
										
										<Button
											className={(valid === false) ? "btn btn-outline-primary disabled" : "btn btn-outline-primary "}
											disabled={(valid === false)} type={"submit"}>Submit</Button>
									
									</Form>
							}
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} md={12} lg={2}></Col>
			</Row>
		</Container>
	)
}

const Footer = (props) => {
	return (
		<Container id={"footer"} style={{padding: 40}}>
			<h5>All rights reserved 2018-2021 @ Datasourcery Limited</h5>
			<p>
				<a href={""} onClick={(e) => {
					e.preventDefault();
					props.setShowTerms(true)
				}}>Terms & conditions</a>
				<a style={{marginLeft: 20}} href={""} onClick={(e) => {
					e.preventDefault();
					props.setShowPrivacy(true)
				}}>Privacy statement</a>
			</p>
		</Container>
	)
}

const Terms = (props) => {
	if (props.showTerms === false) return null;
	return (
		<Offcanvas show={props.showTerms} onHide={() => props.setShowTerms(false)}>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>Terms & conditions</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div>
					
					<p>Terms of Service </p>
					
					<p>IMPORTANT: These terms are binding on you. It is important
						that you read them carefully. These terms govern your access to the Site and
						use of the Services (as defined below). If you do not agree to be bound by
						these Terms you must not use any part of our Site or the Services.</p>
					
					<h6>1. Definitions and interpretation</h6>
					
					<p>1.1 The following words and phrases have specific meanings
						wherever you see them used in these Terms:</p>
					
					<p><b>Confidential Information</b> means any and all
						information of a confidential nature which is obtained by one party in relation
						to the other party in connection with these Terms and a Customer Agreement
						including without limitation any strategies, concepts, budgets, trading terms,
						plans, projections, methods, processes, systems, know how, trade secrets,
						computer software and programs, research data, client lists, intellectual
						property, business or financial information, employee, customer, financier or
						supplier information, or any dealings, transactions, affairs or any other information
						in any form. For the avoidance of doubt, Confidential Information includes all
						Datasourcery Intellectual Property and Customer Data.</p>
					
					<p><b>Customer</b> means the entity (e.g. a company or other
						organisation) which has entered into a Customer Agreement with Datasourcery.</p>
					
					<p><b>Customer Agreement</b> means an agreement under which an
						entity (e.g. a company or other organisation) engages Datasourcery to provide
						Services. </p>
					
					<p><b>Customer Data</b> includes information, data, and content
						(including personal information) owned, held, used or created by the Customer
						supplied by the Customer to Datasourcery.</p>
					
					<p><b>Datasourcery</b> means Datasourcery Limited, a company
						registered in New Zealand under company number 6980813, and Donoranalytics.nz,
						Donoranalytics, WorkVision, our, us and we as well used in these Terms refers
						to Datasourcery.</p>
					
					<p><b>Fees</b> means the fees set out in a Customer Agreement
						or otherwise agreed with a Customer. </p>
					
					<p><b>Force Majeure Event</b> means an event, or a series of
						related events, that is outside the reasonable control of the party affected
						(including failures of the internet or any public telecommunications network,
						hacker attacks, denial of service attacks, virus or other malicious software
						attacks or infections, power failures, industrial disputes affecting any third
						party, changes to the law, disasters, epidemics, pandemics, explosions, fires,
						floods, riots, terrorist attacks and wars).</p>
					
					<p><b>GST</b> means goods and services tax payable under the
						New Zealand Goods and Services Tax Act 1985 or as amended.</p>
					
					<p><b>Intellectual Property Rights</b> means all intellectual
						property rights wherever in the world, whether registrable or unregistrable,
						registered or unregistered, including any application or right of application
						for such rights and these “intellectual property rights” include copyright and
						related rights, database rights, confidential information, trade secrets,
						know-how, business names, trade names, trademarks, service marks, passing off
						rights, unfair competition rights, patents, utility models and rights in
						designs.</p>
					
					<p><b>New Intellectual Property</b> all Intellectual Property
						Rights, including (but not limited to) copyright, in all concepts, designs,
						drawings, specifications, plans, studies, reports, models, software and
						documentation collated, prepared or created in any medium by Datasourcery (or
						persons on behalf of Datasourcery) in carrying out the Services and provided to
						the Customer as deliverables, but not including Datasourcery Intellectual
						Property.</p>
					
					<p><b>Services</b> means Data and Information Strategy, Business
						Analysis, Data Science, Software Development, Business Advisory Services and
						other services provided by Datasourcery from time to time.</p>
					
					<p><b>Site</b> means the website at www.datasourcery.com or
						www.donoranalytics.nz. </p>
					
					<p><b>Terms</b> means these terms of service. </p>
					
					<p><b>Datasourcery Intellectual Property</b> means all
						Intellectual Property Rights owned by or licensed to Datasourcery which are
						used in the provision of the Services, including (but not limited to) the Site,
						any generic material (e.g. library code, standard text, stock imagery and the
						like) that has been used in the past by Datasourcery or which may need to be
						used in future and which does specifically identify the Customer, proprietary
						software, systems, technologies, strategies, manuals, know how, financial
						approaches, business processes and methodologies.</p>
					
					<p><b>You</b> mean the person who accepts these Terms in
						accordance with clause 2 below, and, if clause 2.2 applies, the Customer on
						whose behalf you are acting.</p>
					
					<h6>2. Application of these Terms </h6>
					
					<p>2.1 These Terms are binding and apply to your use of the
						Site and any use of the Services by the Customer. By visiting the Site or
						using the Services, you irrevocably agree to these Terms and our enforcement of
						the Customer Agreement and these Terms against you.</p>
					
					<p>2.2 Where the Services are used or to be used by a Customer
						entity (e.g. a company or other organisation), you confirm that you are
						authorised to agree to these Terms on that Customer entity’s behalf and you
						agree on behalf of that Customer entity that it is bound by these Terms. In
						that case, references to “you” in these Terms include both the person accepting
						these Terms and that Customer entity. </p>
					
					<p>2.3 Please also read our privacy policy because it will
						apply to all the information provided to us. </p>
					
					<p>2.4 You warrant in respect of all personal information about
						any identifiable individual, which is contained in any Customer Data, that you
						have the right to collect and use that personal information for the purposes
						you are using it and to make it available to us and our third party service
						providers so that we can together deliver the Services. You further warrant
						that our and those third parties’ collection, storage, distribution, disclosure
						and other use of that personal information to deliver the Services will not
						breach any privacy, data protection or other similar law in any jurisdiction.</p>
					
					<p>2.5 If you do not agree to be bound by these Terms, you must
						immediately stop accessing the Site and using the Services.</p>
					
					<h6>3. Changes</h6>
					
					<p>3.1 We may revise these Terms at any time by providing you
						with at least 30 days’ prior notice of the change, whether via the Site, by
						email or by any other messaging facility we may use. Continued use after that
						notice constitutes agreement to the changed terms.</p>
					
					<p>3.2 These Terms were last updated on 21st September 2020 (21/09/2020).</p>
					
					<p>4. Our Services </p>
					
					<p>4.1 We will use reasonable efforts to provide the Services:</p>
					
					<p> 4.1.1 in accordance with these Terms and New Zealand
						law;</p>
					
					<p> 4.1.2 exercising reasonable care, skill and diligence;
						and</p>
					
					<p> 4.1.3 using suitably skilled, experienced and qualified
						personnel.</p>
					
					<p>4.2 Our provision of the Services to you is non-exclusive.
						We can provide the Services to any other person.</p>
					
					<h6>5. Maintenance and updates</h6>
					
					<p>5.1 We will maintain and update the Site as and when we
						consider it appropriate to do so.</p>
					
					<p>5.2 Where practicable, we will give you at least 7 days'
						notice of any scheduled maintenance that is likely to affect the availability
						of the Site. We reserve the right to suspend access at any time, without
						notice, to all of ay part of the Site and/or the Services where we consider it
						necessary for security or other emergency reasons. Neither you nor the
						Customer has any claim against us in such circumstances.</p>
					
					<h6>6. Fees/Payment</h6>
					
					<p>6.1 You agree to pay all applicable Fees.</p>
					
					<p>6.2 Unless otherwise agreed in a Customer Agreement, Datasourcery
						will invoice you monthly for Services undertaken in the previous month.</p>
					
					<p>6.3 All Fees exclude GST, which you must pay on taxable
						supplies under these Terms.</p>
					
					<p>6.4 You agree to pay invoice issued by Datasourcery by the
						20th of the month following the date of invoice in cleared funds without
						set-off or deduction.</p>
					
					<h6>7. Your obligations</h6>
					
					<p>7.1 You must comply with all applicable laws, regulations
						and rules when using the Site and Services, and with respect to any Customer
						Data you provide to us.</p>
					
					<p>7.2 You must provide to us, or procure for us, such
						information and documentation as is reasonably necessary to enable us to
						perform our obligations under these Terms.</p>
					
					<p>7.3 You must not use the Services:</p>
					
					<p>7.3.1 in any way that is unlawful, illegal, fraudulent or
						harmful; or</p>
					
					<p>7.3.2 in connection with any unlawful, illegal, fraudulent
						or harmful purpose or activity.</p>
					
					<h6>8. Intellectual Property Rights and Software</h6>
					
					<p>8.1 Datasourcery Intellectual Property Rights:</p>
					
					<p>8.1.1 we own, or have a license to, all rights, title and
						interest, including all Intellectual Property Rights, in and to Datasourcery
						Intellectual property and any suggestions, enhancements, requests,
						recommendations, corrections or other feedback provided by Customers relating
						to Datasourcery Intellectual Property;</p>
					
					<p> 8.1.2 subject to these Terms, we grant you a worldwide,
						non-exclusive, non-transferable, non-sublicensable licence to us and copy Datasourcery
						Intellectual Property to the extent reasonably required to enable you to make
						use of the Services;</p>
					
					<p>8.1.3 we own all rights, title and interest, including all
						Intellectual Property Rights in New Intellectual Property;</p>
					
					<p>8.1.4 subject to you having paid all amounts due and payable
						to us in accordance with a Customer Agreement, we grant you a non-revocable,
						royalty free, non-exclusive license to use the New Intellectual Property to the
						extent reasonably required to enable you to make use of the Services;</p>
					
					<p>8.1.5 we retain all right, title and interest in any source
						or HTML code, make-up files and Datasourcery proprietary software used by Datasourcery
						or its contractors and agents in providing the Services and the Site; and</p>
					
					<p>8.1.6 your rights in relation to any licence in respect of Datasourcery
						Intellectual Property and New Intellectual Property is conditional upon, on
						each occasion, you having paid all amounts due and payable to Datasourcery in
						accordance with a Customer Agreement concerning the Services which produced the
						New Intellectual Property or made use of Datasourcery Intellectual Property.</p>
					
					<p>8.2 Your Intellectual Property Rights:</p>
					
					<p>8.2.1 you own all right, title and interest, including
						Intellectual Property Rights, in and to Customer Data; and</p>
					
					<p>8.2.2 you grant us a royalty free, perpetual, worldwide,
						transferable, non-exclusive licence to use Customer Data for the term of a
						Customer Agreement to the extent reasonably necessary to enable Datasourcery to
						provide the Services. </p>
					
					<p>8.3 Nothing in these Terms will operate to assign or
						transfer any Intellectual Property Rights from us to you or the Customer, or
						from you or the Customer to us.</p>
					
					<p>8.4 If we reasonably determine, or any third party alleges,
						that the use of the Services by you in accordance with these Terms, including,
						but not limited to your use of the New Intellectual Property, infringes any
						person’s Intellectual Property Rights, we may at our own cost and expense:</p>
					
					<p>8.4.1 modify or revoke the Services and/or the New
						Intellectual Property in such a way that they no longer infringe the relevant
						Intellectual Property Rights; or</p>
					
					<p>8.4.2 procure for you the right to use the Services and/or
						New Intellectual Property in accordance with these Terms.</p>
					
					<p>8.5 Where we provide any software to you to enable you to
						use the Services:</p>
					
					<p>8.5.1 we retain all Intellectual Property Rights in that
						software (including both the human readable and machine-readable parts);</p>
					
					<p>8.5.2 you have no right to access the source or object code
						of the software other than in running the software to use the Services and you
						agree not reverse engineer, reproduce, duplicate, copy, sell, assign,
						sublicence, resell or exploit any portion of the software other than as
						strictly necessary for you (and not anyone else) to use the Services;</p>
					
					<p>8.5.3 you will only use the software for the purposes it was
						provided;</p>
					
					<p>8.5.4 all proprietary notices on or in the software are to
						be retained and not altered or removed; and</p>
					
					<p>8.5.5 you will keep your copy of the software secure and not
						let anyone else access or use it and you will not use the software to provide
						services that are the same or equivalent to the Services, to anyone else.</p>
					
					<h6>9. Viruses</h6>
					
					<p>9.1 We do not guarantee that the Site will be secure or free
						from bugs or viruses. You are responsible for configuring your information
						technology, computer programmes and site in order to access the Site (if
						applicable). You should use your own virus protection software. </p>
					
					<p>9.2 We will not be liable for any loss or damage caused by a
						virus, distributed denial-of-service attack, or other technologically harmful
						material that may infect your computer equipment, computer programs, data or
						other proprietary material due to your use of the Site or to your downloading
						of any material from the Site.</p>
					
					<p>9.3 You must not misuse the Site by knowingly introducing
						viruses, trojans, worms, logic bombs or other material which is malicious or
						technologically harmful. You must not attempt to gain unauthorised access to
						the Site, the server on which the Site is stored or any server, computer or
						database connected to the Site. You must not attack the Site (if applicable)
						via a denial-of-service attack or a distributed denial-of service attack.</p>
					
					<h6>10. Warranties and exclusions</h6>
					
					<p>10.1 We warrant to you that:</p>
					
					<p>10.1.1 we have the legal right and authority to enter into
						and to perform our obligations under these Terms;</p>
					
					<p>10.1.2 we will comply with all applicable legal requirements
						applying to the exercise of our rights and the fulfilment of our obligations
						under these Terms;</p>
					
					<p>10.1.3 the use of the Services in accordance with these
						Terms will not breach any legal requirements applicable under New Zealand law;</p>
					
					<p>10.1.4 we have or have access to all necessary know-how,
						expertise and experience to perform our obligations under these Terms.</p>
					
					<p>10.2 You acknowledge that:</p>
					
					<p>10.2.1 complex software is never wholly free from defects,
						errors and bugs, and Datasourcery gives no warranty or representation that the
						Site will be wholly free from such defects, errors and bugs;</p>
					
					<p>10.2.2 we dos does not warrant or represent that the Site
						will be compatible with any device not approved by us; and</p>
					
					<p>10.2.3 we do not give any representation, warranty or other
						assurance whatsoever as to the fitness for purpose, functionality, operation,
						continuation or use of any device that you may use to access the Site.</p>
					
					<p>10.3 All of the parties' warranties and representations in
						respect of the subject matter of these Terms are expressly set out in these
						Terms. No further terms are implied. </p>
					
					<p>&nbsp;</p>
					
					<p>10.4 You agree that you are using the Services for the
						purpose of a business and that the Consumer Guarantees Act 1993 (NZ) and any
						other similar consumer protection legislation in any jurisdiction does not
						apply to the Services or the Site.</p>
					
					<h6>11. Indemnities</h6>
					
					<p>11.1 We will indemnify and will keep indemnified you against
						any and all liabilities, damages, losses, costs and expenses (including legal
						expenses and amounts reasonably paid in settlement of legal claims) suffered or
						incurred by you and arising out of any claim brought against the Customer by a
						third party to the extent that the claim alleges that your use of the Services
						infringes the third party's Intellectual Property Rights (a Datasourcery
						Indemnity Event).</p>
					
					<p>11.2 You must:</p>
					
					<p>11.2.1 upon becoming aware of an actual or potential Datasourcery
						Indemnity Event, notify us;</p>
					
					<p>11.2.2 provide to us all such assistance as may be
						reasonably requested by us in relation to the Datasourcery Indemnity Event;</p>
					
					<p>11.2.3 allow us the exclusive conduct of all disputes,
						proceedings, negotiations and settlements with third parties relating to the Datasourcery
						Indemnity Event; and</p>
					
					<p>11.2.4 not admit liability to any third party in connection
						with the Datasourcery Indemnity Event or settle any disputes or proceedings
						involving a third party and relating to the Datasourcery Indemnity Event
						without our prior written consent, and our obligation to indemnify you under
						clause 11.1 will not apply unless you comply with the requirements of this
						clause 11.2.</p>
					
					<p>11.3 We will not be liable to you under a Datasourcery
						Indemnity Event to the extent that the third party's claim arises from the
						Customer's breach of the Customer Agreement or these Terms, use of the Services
						in a manner not reasonably contemplated by the Customer Agreement or these
						Terms, or any Customer Data or third party's data.</p>
					
					<p>11.4 You agree to indemnify, and hold us and our respective
						officers, directors, employees and agents, harmless from and against any
						claims, liabilities, damages, losses, and expenses, including, without
						limitation, any tax, legal and/or accounting fees, arising out of or in
						connection with your access to or use of the Site, Services or your violation
						of these Terms.</p>
					
					<h6>12. Liability</h6>
					
					<p>12.1 The limitations and exclusions of liability set out in
						this clause 12 and elsewhere in a Customer Agreement and these Terms govern all
						liabilities arising under a Customer Agreement and these Terms or relating to
						the subject matter of a Customer Agreement and these Terms, including
						liabilities arising in contract, in tort (including negligence) and for breach
						of statutory duty, except to the extent expressly provided otherwise in a
						Customer Agreement and these Terms.</p>
					
					<p>12.2 Neither party is liable to the other under or in
						connection with these Terms of a Customer Agreement for:</p>
					
					<p>12.2.1 loss of profit, opportunity, revenue, savings,
						business, use, data (including Customer Data), or goodwill;</p>
					
					<p>12.2.2 consequential, indirect, incidental or special damage
						or loss of any kind, including damage to the Customer’s systems; or</p>
					
					<p>12.2.3 any losses arising out of a Force Majeure Event.</p>
					
					<p>12.3 This clause 12 does not apply to limit your liability
						to pay the Fees for the Services.</p>
					
					<p>12.4 Neither party will be responsible, liable, or held to
						be in breach of these Terms or a Customer Agreement for any failure to perform
						its obligations under these Terms or a Customer Agreement or otherwise, to the
						extent that the failure is caused by the other party failing to comply with its
						obligations under these Terms or a Customer Agreement, or by the negligence or
						misconduct of the other party or its personnel.</p>
					
					<p>12.5 Each party must take reasonable steps to mitigate any
						loss or damage, cost or expense it may suffer or incur arising out of anything
						done or not done by the other party under or in connection with these Terms or
						a Customer Agreement.</p>
					
					<p>12.6 Our aggregate liability to you under a Customer Agreement
						and these Terms will not exceed the total amount received by us under such
						Customer Agreement in the 12-month period preceding the commencement of the
						event giving rise to the liability.</p>
					
					<h6>13. Insurance</h6>
					
					<p>13.1 We will maintain and keep in force during the term of a
						Customer Agreement insurance of such types and in such amounts as may be
						notified to you from time to time. [Certificates of insurance will be provided
						to the Customer upon request.]</p>
					
					<h6>14. Confidentiality</h6>
					
					<p>14.1 In this clause 14, Disclosing Party means the party
						disclosing Confidential Information and Receiving Party means the party
						receiving the Confidential Information.</p>
					
					<p>14.2 The Receiving Party must:</p>
					
					<p>14.2.1 keep the Confidential Information strictly
						confidential;</p>
					
					<p>14.2.2 not disclose the Confidential Information to any
						person without the Disclosing Party's prior written consent, and then only
						under conditions of confidentiality no less onerous than those contained in a
						Customer Agreement and these Terms;</p>
					
					<p>14.2.3 use the same degree of care to protect the
						confidentiality of the Confidential Information as the Receiving Party uses to
						protect the Receiving Party's own confidential information of a similar nature,
						being at least a reasonable degree of care;</p>
					
					<p>14.2.4 act in good faith at all times in relation to the
						Confidential Information; and</p>
					
					<p>14.2.5 not use any of the Confidential Information for any
						purpose other than media production through the use of the Services.</p>
					
					<p>14.3 Notwithstanding clause 14.2, the Receiving Party may
						disclose the Confidential Information to its officers, employees, professional
						advisers, insurers, agents and contractors who have a need to access the
						Confidential Information for the performance of their work with respect to the
						permitted purpose and who are bound by a written agreement or professional
						obligation to protect the confidentiality of the Confidential Information.</p>
					
					<p>14.4 This clause 14 imposes no obligations upon a Receiving
						Party with respect to Confidential Information that:</p>
					
					<p>14.4.1 is known to the Receiving Party before disclosure
						under the Customer Agreement and these Terms and is not subject to any other
						obligation of confidentiality;</p>
					
					<p>14.4.2 is or becomes officially known through no act or
						default of the Receiving Party; or</p>
					
					<p>14.4.3 is obtained by the Receiving Party from a third party
						in circumstances where the Receiving Party has no reason to believe that there
						has been a breach of an obligation of confidentiality.</p>
					
					<p>14.5 The restrictions in this clause 14 do not apply to the
						extent that any Confidential Information is required to be disclosed by any law
						or regulation, by any judicial or governmental order or request, or pursuant to
						disclosure requirements relating to the listing of the securities of a party on
						any recognised stock exchange.</p>
					
					<p>14.6 The provisions of this clause 14 will continue in force
						indefinitely following the termination of a Customer Agreement.</p>
					
					<h6>15. Force Majeure Event</h6>
					
					<p>15.1 If a Force Majeure Event gives rise to a failure or
						delay in either party performing any obligation under these Terms (other than
						any obligation to make a payment), that obligation will be suspended for the
						duration of the Force Majeure Event.</p>
					
					<p>15.2 A party that becomes aware of a Force Majeure Event
						which gives rise to, or which is likely to give rise to, any failure or delay
						in that party performing any obligation under these Terms, must:</p>
					
					<p>15.2.1 promptly notify the other; and</p>
					
					<p>15.2.2 inform the other of the period for which it is
						estimated that such failure or delay will continue.</p>
					
					<p>15.3 A party whose performance of its obligations under
						these Terms is affected by a Force Majeure Event must take reasonable steps to
						mitigate the effects of the Force Majeure Event.</p>
					
					<h6>16. Disputes</h6>
					
					<p>16.1 Any and all disputes arising out of these Terms, a
						Customer Agreement, or any termination, will be determined by binding
						arbitration in English under the Arbitration Act 1996 in Auckland, New Zealand,
						by one arbitrator who will be a lawyer knowledgeable in relevant technology
						matters appointed by the President for the time being of the Arbitrators and
						Mediators Institute of New Zealand Incorporated (AMINZ) on a request by either
						you or us. </p>
					
					<p>16.2 The Arbitrator shall permit the parties and any
						witnesses to appear by videoconference that we will organise and pay for if
						reasonably necessary. The costs of arbitration shall be shared equally unless
						the arbitrator otherwise specifies (e.g. where the arbitrator determines that
						the dispute is frivolous or vexatious).</p>
					
					<h6>17. General</h6>
					
					<p>17.1 Assignment: We may assign or transfer our rights under
						these Terms or a Customer Agreement, at our sole discretion, without
						restriction. You may not assign or transfer your rights under these Terms or a
						Customer Agreement without our prior written consent, which may be withheld in
						our absolute discretion.</p>
					
					<p>17.2 Notices: Any notices or other communications permitted
						or required under these Terms, including those regarding modifications to these
						Terms, will be in writing and given by us:</p>
					
					<p>17.2.1 via email (in each case to the address that you
						provide); or</p>
					
					<p>17.2.2 via the Site.</p>
					
					<p>For notices made by email, the date of receipt will be
						deemed the date on which such notice is sent.</p>
					
					<p>17.3 No waiver: No failure or delay by us to exercise any
						right or remedy provided under a Customer Agreement or these Terms or by law
						will constitute a waiver of that or any other right or remedy, nor will it
						prevent or restrict the further exercise of that or any other right or remedy.
						No single or partial exercise of such right or remedy by us will prevent or
						restrict the further exercise of that or any other right or remedy.</p>
					
					<p>17.4 Survival: The obligations of the parties under clauses
						8 (Intellectual Property), 11 (Indemnities), 12 (Liability), 14 (Confidential
						Information), 16 (Disputes) and this clause 17 shall survive the expiry or the
						termination of this Agreement.</p>
					
					<p>17.5 Severability: If any provision or part-provision of
						these Terms is or becomes void, illegal or unenforceable, it will be deemed
						modified to the minimum extent necessary to make it valid, legal and
						enforceable. If such modification is not possible, the relevant provision or
						part-provision will be severable and deemed to be deleted, and will not affect
						the validity, legality or enforceability of the remaining provisions.</p>
					
					<p>17.6 Governing Law: These Terms will be governed by and
						construed in accordance with New Zealand law. You agree to submit to the
						exclusive jurisdiction of the courts of New Zealand with respect to any claim
						or matter arising out of or in connection with these Terms or their
						termination.</p>
					
					<p>&nbsp;</p>
					
					<p>If you have any questions or concerns in relation to these
						Terms, please <a href="mailto:info@datasourcery.com">contact us</a>.</p>
				
				</div>
			</Offcanvas.Body>
		</Offcanvas>
	)
}

const Privacy = (props) => {
	if (props.showPrivacy === false) return null;
	return (
		<Offcanvas show={props.showPrivacy} onHide={() => props.setShowPrivacy(false)}>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>Privacy</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div>
					<p>This policy explains how Datasourcery Limited, a company
						incorporated in New Zealand (company number 6980813) (<b>Datasourcery, Donoranalytics,
							Donoranalytics.nz, WorkVision, us, we</b>), collects, holds and processes
						personal information and other data. &nbsp;We only provide services to our
						business customers and not directly to individuals, but we recognise that in
						doing so we will be dealing with our customers’ data and that may include
						personal information of their customers, clients employees, users or others to
						whom they provide their services.</p>
					
					<p>We take the privacy of personal information seriously, and
						we comply with the Privacy Act 1993 (<b>NZ Privacy Act</b>), and the Privacy
						Act 1988 (Cth) which incorporates the Australian Privacy Principles (<b>AU
							Privacy Act</b>).</p>
					
					<p>“<b>Personal information</b>” means any information relating
						to a particular individual that can identify that person either directly or
						indirectly (i.e. &nbsp;by reference to other information we have access to).
						The term “<b>processing</b>” includes collection, storage, and all of the ways
						we use personal information when we provide our Site and services.</p>
					
					<h6>1. How we collect personal information</h6>
					
					<p>(a)&nbsp;Personal information provided to us</p>
					
					<p>- We do not collect personal information directly from
						individuals other than under our contracted services provided to our business
						customers. &nbsp;We rely on our customers having appropriate authorisation to
						allow us to use their customers’, users’, clients’, employees’, or other third
						parties’ data, including personal information which is necessary for the
						provision of our services. &nbsp;Where a customer does not have that authority,
						it will indemnify us fully should the lack of that authority cause us any loss,
						damage, or cost (including our full legal costs).</p>
					
					<p>- When a customer engages us to provide services we collect
						that customer’s name, business address, phone number, the client contact
						person’s name and contact detail, and other information that a customer
						provides to us which is reasonably necessary to enable us to provide our
						services.</p>
					
					<p>- When a customer contacts us for support or other enquiries,
						we may collect the customer’s name, contact person’s name, telephone number,
						email address and any other information the contact person provides to us.</p>
					
					<p>(b)&nbsp;Technical information we may collect automatically:</p>
					
					<p>- When you interact with our sites (datasourcery.com,
						donoranalytics.co.nz) (Site) we may collect technical information about your
						equipment, browsing actions and patterns. This includes internet protocol (IP)
						address, browser type and version and time zone settings, browser plug-in types
						and versions, operating system and platform and other technology on the devices
						you use to access our Site. We collect this information by using cookies and
						other similar technologies. Please refer to the Appendix for further details on
						our use of cookies. &nbsp;</p>
					
					<p>- When you access our Site through a location enabled device
						we collect and process real time GPS-based information about your device’s
						location. If you do not consent to us collecting location-based information you
						can disable the GPS or other location tracking functions from your device if
						your device allows you to do this.&nbsp;</p>
					
					<p>(c)&nbsp;Information&nbsp;about minors. Our Site and
						services do not address anyone under the age of 18. We do not knowingly collect
						personal identifiable information from children under 18. In the case we
						discover that a child under 18 has provided us with personal information, we
						immediately delete this from our servers. If you are a parent or guardian and
						you are aware that your child has provided us with personal information, please
						contact us so that we will be able to do necessary actions.</p>
					
					<h6>2. How and why we use personal information&nbsp;</h6>
					
					<p>We collect, hold, and use personal information and technical
						information for the primary purpose of providing our services to our business
						customers. &nbsp;</p>
					
					<p>We may also use personal information for the following
						secondary purposes:</p>
					
					<p>- to provide our customers with support and customer care services;</p>
					
					<p> - to improve our Site and services;</p>
					
					<p> - to let our customers know about new services that we think
						they may be interested in;</p>
					
					<p >- to provide our customers with updates that they have
						subscribed to receive, such as our cyber guidance update. Customers can
						unsubscribe at any time by contacting us at the address set out in are set out
						in paragraph 14 below or clicking on the unsubscribe link found on the email;</p>
					
					<p> - to bill our customers for services they have engaged us to
						provide;</p>
					
					<p> - to conduct research and statistical analysis to improve
						our services;</p>
					
					<p> - to protect and/or enforce our legal rights and interests,
						including defending any claim; and</p>
					
					<p> - to provide to a government agency or law enforcement as
						allowed under the AU Privacy Act and the NZ Privacy Act (and any amendment).
						For further information how we share information for legal purposes please see
						paragraph 3.</p>
					
					<h6>3.&nbsp;How we may share personal information&nbsp;</h6>
					
					<p>We may disclose or share personal information with third
						parties in the following circumstances:</p>
					
					<p><b> - With customer consent</b>. We may share personal information
						with third parties where a customer has consented or requested us to do so.</p>
					
					<p><b> - Third party service providers</b>. &nbsp;We may share
						personal information with companies that support our Site and our services,
						including any person that hosts or maintains any underlying IT system or data
						centre that we use to provide our Site or services. &nbsp; &nbsp;</p>
					
					<p><b> - Legal purposes</b>.<b>&nbsp;</b>Where it is legally
						required by a third party, government agency or law enforcement authority in
						any jurisdiction (in which case we will generally require a production order or
						similar court order. Where we are to make any disclosure of customer data, we
						will provide our relevant customers with as much advance notice as is
						reasonable in the circumstances, provided we are not prevented by law from doing
						so.</p>
					
					<p><b> - To enforce our rights, prevent fraud and for safety</b>.<b>&nbsp;</b>To
						protect and defend our rights, property or safety or that of third parties,
						including enforcing our privacy policy and any terms of use, or in connection
						with investigating and preventing fraud or other suspected illegality or
						security issues.</p>
					
					<p><b> - Business sale</b>. In the event we sell our business we
						may disclose personal information to the prospective buyer. If substantially
						all of our assets are acquired by a third party, personal information will be
						one of the transferred assets.<b>&nbsp;</b></p>
					
					<h6>4. Security</h6>
					
					<p>We take reasonable precautions, including administrative,
						technical, and physical measures, to safeguard personal information against
						loss, theft, and misuse, as well as against unauthorised access, disclosure,
						alteration, and destruction.</p>
					
					<p>Unfortunately, no data transmissions over the Internet can
						be guaranteed to be 100% secure. If you have reason to believe that your
						interaction with us is no longer secure please contact us immediately. Our
						contact details are set out in paragraph 14 below.</p>
					
					<h6>5. How long do we keep personal information&nbsp;</h6>
					
					<p>We retain personal information for as long as it is needed
						to be able to provide our services to a customer. Once our services have been
						provided we will only keep data if it is necessary or required to meet legal or
						regulatory requirements, resolve disputes, or to prevent fraud or abuse.</p>
					
					<h6>6. Third-party sites and services</h6>
					
					<p>Our Site may contain links to third-party websites,
						products, and services. Information collected by third parties, which may
						include such things as location data or contact details, is governed by their
						privacy practices. We encourage you to learn about the privacy practices of
						those third parties.</p>
					
					<h6>7. Social media platforms</h6>
					
					<p>Please be aware that if you share any of your personal
						information on a third party social media website or app (e.g. Facebook and
						LinkedIn), your personal information may be collected or used by the
						third-party website or app and/or the users of these sites, and could result in
						you receiving unsolicited messages. We encourage you to review the privacy
						policies and settings of the social media websites and apps you interact with.
						&nbsp;&nbsp;</p>
					
					<h6>8. International transfer of data&nbsp;</h6>
					
					<p>Our business is operated in New Zealand but businesses that
						support our services may be located outside Australia or New Zealand. This
						means that information may be held and processed outside Australia or New
						Zealand, and also transferred between Australia and New Zealand.</p>
					
					<h6>9. Privacy breaches</h6>
					
					<p>A privacy breach occurs where there is:</p>
					
					<p>- unauthorised or accidental access to, or disclosure,
						alteration, loss, or destruction of, personal information held by us; or</p>
					
					<p>- an action that prevents us from accessing personal
						information on either a temporary or permanent basis.</p>
					
					<p>If we learn of a privacy breach involving any of our
						services we will assess whether the privacy breach is likely to cause serious
						harm to an affected individual or individuals.</p>
					
					<p>If our assessment finds that the privacy breach has caused
						serious harm to an affected individual or individuals, or is likely to do so,
						we will notify the individual or individuals and the Australian or New Zealand
						Privacy Commissioner (as applicable) as soon as practicable after we become
						aware that a notifiable privacy breach has occurred.</p>
					
					<h6>10.&nbsp;Accessing and correcting your personal information&nbsp;</h6>
					
					<p>Subject to certain grounds for refusal set out in the AU
						Privacy Act and NZ Privacy Act, you have the right to request confirmation from
						us that we hold personal information about you and a copy of such personal
						information. You are also entitled to request the correction of the information
						we hold about you. If you would like to exercise either of these rights, please
						contact us at&nbsp;support@datasourcery.com.&nbsp;&nbsp;</p>
					
					<p>Your email should provide evidence of who you are and the
						details of your request (e.g. the personal information, or the correction, that
						you are requesting).&nbsp;</p>
					
					<h6>11. Complaints</h6>
					
					<p>If you wish to complain about an alleged privacy breach, we
						encourage you to lodge the complaint with us in writing (using the contact
						details set out in paragraph 14). We will respond to your complaint within a
						reasonable time and within any applicable timing requirements prescribed by
						law, to try and work with you to resolve the issue.</p>
					
					<h6>12. Communications with you</h6>
					
					<p>You may opt out of emails you receive from us by
						emailing&nbsp;info@datasourcery.com&nbsp;or by clicking the unsubscribe link
						found in the email.&nbsp;</p>
					
					<h6>13.&nbsp;Changes to this privacy policy</h6>
					
					<p>We may revise, modify or update this privacy policy from
						time to time by publishing an updated privacy policy on our Site. We encourage
						you to regularly check our Site. We will notify you by email of any material
						changes to this privacy policy.<b>&nbsp;</b></p>
					
					<h6>14. How to contact us</h6>
					
					<p>If you have any questions about this privacy policy or your
						rights you may reach us at&nbsp;<a href="mailto:info@datasourcery.com">info@datasourcery.com</a>
					</p>
					
					<p>Date last updated 21<sup>st</sup> September 2020</p>
					
					<p>Appendix</p>
					
					<h6>Cookies and other technologies</h6>
					
					<p>To improve your experience, our Site may use “cookies” and
						other technologies such as pixel tags and web beacons. A cookie is a small text
						file that our Site may place on your device as a tool to remember your
						preferences. &nbsp;These technologies help us better understand user behaviour,
						tell us which parts of our Site people have visited, and facilitate and measure
						the effectiveness of our products and services. Pixel tags enable us to send
						email messages in a format that customers can read, and they tell us whether
						mail has been opened. We may use this information to reduce or eliminate
						messages sent to customers.</p>
					
					<p>Our Site uses service Google Analytics in order to better
						understand our users’ needs and optimise our service experience. Google
						Analytics is a service which transmits website traffic data to Google servers
						in the United States. Google Analytics does not identify individual users or
						associate your IP address with any other data held by Google. We use reports
						provided by Google Analytics to help us understand website traffic and webpage
						usage.</p>
					
					<p>By visiting our Site, you consent to the processing of data
						about you by Google in the manner described in&nbsp;<a
							href="https://www.google.com/policies/privacy/partners/">Google’s Privacy
							Policy</a>. You may refuse the use of cookies by selecting the appropriate
						settings on your browser, however please note that if you do this you may not
						be able to use the full functionality of our Site and your use of our services
						will be impaired.</p>
					
					<p>In some of our email messages, we use a “click-through URL”
						linked to content on our Site. When customers click one of these URLs, they
						pass through a separate web server before arriving at the destination page on
						our Site. We track this click-through data to help us determine interest in
						particular topics and measure the effectiveness of our communications. If you
						do not consent to us collecting information on your click-through activity, do
						not click text or graphic links in the email messages.</p>
					
					<h6>Privacy Collection Statement</h6>
					
					<p>Datasourcery Limited, a company incorporated in New Zealand
						(company number 5407342) (Datasourcery, Donoranalytics, Donoranalytics.nz, WorkVision,
						us, we), complies with the Privacy Act 1993 and the Privacy Act 1988 (Cth)
						which incorporates the Australian Privacy Principles.</p>
					
					<p>Our full privacy policy contains information about how we
						collect, use and disclose your personal information, how you can contact us to
						obtain a copy of personal information held by us or with any questions or
						privacy related complaints, and how we will deal with a privacy related
						complaint.</p>
					
					<p>Other than technical information about your equipment,
						browsing actions and patterns, which we may collect automatically when you
						interact with our site (www.datasourcery.com, www.donoranalytics.nz,
						www.workvision.co.nz), we collect personal information directly from our
						customers.</p>
					
					<p>The primary purpose for which we collect personal information
						is to provide our services to our business customers. You can find out more
						about the secondary purposes for which we use your personal information in our
						full privacy policy. If you choose not to provide us with the requested
						information we may not be able to provide you with our services.</p>
					
					<p>We may share your personal information with our third-party
						service providers in the course of operating our business and other third
						parties where it is required by law, necessary to enforce our rights, prevent
						fraud and for safety, in the event we sell our business.</p>
					
					<p>Our business is operated in New Zealand but businesses that
						support our services may be located outside New Zealand. This means that
						information may be held and processed outside New Zealand. </p>
					
					<p>You have a right to access any personal information we hold
						about you, and you may ask us to update or correct your personal information. </p>
				</div>
			</Offcanvas.Body>
		</Offcanvas>
	)
}

export default function Home() {
	
	const [showPrivacy, setShowPrivacy] = useState(false);
	const [showTerms, setShowTerms] = useState(false);
	
	return (
		<div>
			<NavMenu/>
			<Hero/>
			<Features/>
			<Clients/>
			<Projects/>
			<Engagement/>
			<Contact/>
			<Footer setShowTerms={setShowTerms} setShowPrivacy={setShowPrivacy}/>
			<Terms setShowTerms={setShowTerms} showTerms={showTerms}/>
			<Privacy setShowPrivacy={setShowPrivacy} showPrivacy={showPrivacy}/>
		</div>
	);
}
