import React from "react";
import ParticleField from "react-particles-webgl";

export default function Background_WEBGL() {

    const config = {
        showCube: false,
        dimension: "2D",
        velocity: 0.5,
        boundaryType: "passthru",
        antialias: true,
        direction: {
            xMin: -1,
            xMax: 1,
            yMin: -1,
            yMax: 1,
            zMin: -1,
            zMax: 1
        },
        lines: {
            colorMode: "solid",
            color: "#3FB568",
            transparency: 0.0,
            limitConnections: true,
            maxConnections: 20,
            minDistance: 60,
            visible: true
        },
        particles: {
            colorMode: "solid",
            color: "#262262",
            transparency: 0.3,
            shape: "circle",
            boundingBox: "canvas",
            count: 200,
            minSize:80,
            maxSize: 500,
            visible: true
        },
        cameraControls: {
            enabled: false,
            enableDamping: true,
            dampingFactor: 0.2,
            enableZoom: true,
            autoRotate: false,
            autoRotateSpeed: 0.3,
            resetCameraFlag: true
        }
    };

    return (
        <div className="background">
            <ParticleField config={config} />
        </div>
    );
}
